import React from 'react'

import { Button, Spacer, Text, View } from '@cnd/elements'

import useIsLandscape from 'hooks/useIsLandscape'

import Header from '../Header'

import Copywrite from '../Copywrite'

import Image from 'next/image'
import { useRouter } from 'next/router'
import BreezeHero from './BreezeHero'
import AiCustomerServiceHero from './AiCustomerServiceHero'
import AiTeamSupportHero from './AiTeamSupportHero'
import { HeroScrollDemo } from './ScrollBreezeHero'

import WobbleCard from '@components/WobbleCard'
import GridBackground from '@components/GridBackground'
import TextGenerateEffect from '@components/TextGenerateEffect'

const BreezePage = () => {
  const isLandscape = useIsLandscape()
  const router = useRouter()

  const goToGetStarted = () => router.push(`/get-started`)

  ////images/ai_customer_service.png

  return (
    <>
      {/* <GridBackground> */}
      <View
        style={{
          background: `url(splash_no_logo.webp)`,
          backgroundSize: 'cover',
          backgroundPosition: 'bottom center',
          width: '100vw',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Header
          actionButtonClick={goToGetStarted}
          altButtonOnClick={() => router.push(`/investors`)}
          altButtonText="Company"
          actionButtonText={'Start'}
        />
        <HeroScrollDemo />
        <View center style={{ flex: 1, marginBottom: 170, position: 'relative', top: -40 }}>
          <View
            motion
            whileTap={{ scale: 0.8 }}
            center
            style={{
              height: 70,
              paddingBlock: 10,
              width: 230,
              background: 'white',
              borderRadius: 100,
            }}
            onClick={goToGetStarted}
          >
            <Text weight={700} size={18}>
              Start Automating
            </Text>
          </View>
        </View>
      </View>
      <View
        style={{
          paddingLeft: 20,
          paddingRight: 20,
          paddingBottom: 150,
          background: 'url(images/city_no_logo.webp)',
          backgroundSize: 'cover',
          backgroundPosition: 'top center',
          width: '100vw',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 max-w-7xl mx-auto w-full">
          <WobbleCard
            backgroundColor="#31221c"
            containerClassName="col-span-1 lg:col-span-2 h-full bg-pink-800 min-h-[500px] lg:min-h-[300px]"
            className=""
          >
            <div className="max-w-xs" style={{ paddingLeft: 15 }}>
              <Text size={32} weight={500} color="rgba(255,255,255)">
                Designed to Delight
              </Text>
              <Spacer height={10} />
              <Text size={17} weight={400} color="rgba(255,255,255)">
                Your teams must love interacting with all auto management tools.
              </Text>
            </div>
            <Image
              src="/images/app_screen_example_2.webp"
              width={210}
              height={410}
              alt="app image"
              style={
                isLandscape
                  ? { right: '8%', top: '17%' }
                  : {
                      top: '53%',
                      right: '50%',
                      width: '71%',
                      height: 'auto',
                      transform: `translateX(50%)`,
                    }
              }
              className="absolute -right-4 lg:-right-[40%] -bottom-10 object-contain rounded-2xl"
            />
          </WobbleCard>
          <WobbleCard backgroundColor="#457e82" containerClassName="col-span-1 min-h-[300px]">
            <Text size={32} weight={500} color="rgba(255,255,255)">
              A.I. Chat
            </Text>
            <Spacer height={10} />
            <Text size={17} weight={400} color="rgba(255,255,255)">
              Chat with menus, staff handbooks, processes and more...
            </Text>
          </WobbleCard>
          <WobbleCard
            backgroundColor="#3c494c"
            containerClassName="col-span-1 lg:col-span-3 bg-blue-900 min-h-[500px] lg:min-h-[600px] xl:min-h-[300px]"
          >
            <div className="max-w-xs" style={{ paddingLeft: 15 }}>
              <Text size={32} weight={500} color="rgba(255,255,255)">
                Gamify your Day
              </Text>
              <Spacer height={10} />
              <Text size={17} weight={400} color="rgba(255,255,255)">
                Earn points and prizes by competing with teams in other departments or on other days of the
                week.
              </Text>
            </div>
            <Image
              src="/images/app_screen_example_1.webp"
              width={310}
              height={410}
              alt="app example image"
              style={
                isLandscape
                  ? { right: '8%', top: '10%' }
                  : {
                      top: '53%',
                      right: '50%',
                      width: '71%',
                      height: 'auto',
                      transform: `translateX(50%)`,
                    }
              }
              className="absolute -right-10  lg:-right-[20%] -bottom-10 object-contain rounded-2xl"
            />
          </WobbleCard>
        </div>
      </View>
      <View style={{ height: 20, background: `#457e82` }} />
      {/* 
      <View style={{ position: 'relative' }}>
        <GridBackground>
          <View></View>
        </GridBackground>
      </View>{' '}
      */}
      {/* </GridBackground> */}

      {/* <BreezeHero /> */}
      {/* <Spacer height={100} /> */}
      {/* <AiCustomerServiceHero /> */}
      {/* <Spacer height={100} /> */}
      {/* <AiTeamSupportHero /> */}
      <View
        style={{
          flex: 1,
          position: 'relative',
          backgroundColor: '#22181b',
          height: 400,
        }}
      >
        <View
          style={{
            backgroundImage: 'url(/images/noise.webp)',
            backgroundSize: '30%',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            opacity: 0.03,
            zIndex: 2,
          }}
        />
        <View
          center
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,

            zIndex: 5,

            background: `url(splash_no_logo.webp)`,
            backgroundSize: 'cover',
            backgroundPosition: 'top center',

            backgroundRepeat: 'no-repeat',
          }}
        >
          <View
            motion
            whileTap={{ scale: 0.8 }}
            animate={{
              y: [0, 15, 0],
              scale: [1, 1.1, 1],
            }}
            // initial={{
            //   y: 0,
            //   scale: 0.95,
            // }}
            transition={{
              type: 'spring',
              repeat: Infinity,
              repeatDelay: 4,
              repeatType: 'reverse',

              stiffness: 300,
              damping: 10,
            }}
            center
            style={{
              height: 70,
              paddingBlock: 10,
              width: 230,
              background: 'white',
              borderRadius: 100,
            }}
            onClick={goToGetStarted}
          >
            <Text weight={700} size={18}>
              Start Automating
            </Text>
          </View>
        </View>
      </View>
      <View
        style={{
          zIndex: 100,
          overflow: 'scroll',
          background: 'rgba(0,0,0,1)',
          backdropFilter: 'blur(10px)',
          padding: 40,

          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: 'rgba(0,0,0,0.07)',
        }}
      >
        <Copywrite />
      </View>
    </>
  )
}
export default BreezePage
